@import url('https://fonts.googleapis.com/css2?family=Dangrek&family=Didact+Gothic&family=Montserrat:wght@600&family=Quicksand:wght@700&display=swap');

.App {
  /* text-align: center; */
  z-index: -1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo{
  display: flex;
  padding: 15px 40px;
  
}

.below-iframe {
  margin-top: 10vh; /* Adjust the margin to control the spacing */
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 50px;
  height: 80vh;
}

.title-main{
  align-items: center;
}

.title-text{
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  font-family: 'Kantumruy Pro', sans-serif;
  font-family: 'Poppins', sans-serif;
  color: white;

}

.title{
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  font-family: 'Kantumruy Pro', sans-serif;
  font-family: 'Poppins', sans-serif;

}

.main-logo{
  background-color: white;
}

.box-title{
  width: 40vh;
  
}

.home-text{
  text-align: left;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;

}

@keyframes changeColor {
  0%, 100% {
    color: rgb(82, 185, 90); /* Initial and final color */
  }
  25% {
    color: rgba(199, 211, 28, 0.774); /* Color change at 1 second */
  }
  50% {
    color: rgb(41, 179, 59); /* Color change at 2 seconds */
  }
  75% {
    color: orange; /* Color change at 3 seconds */
  }
}

.coming {
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  animation: changeColor 30s linear infinite;
  
}

.text-coming{
  background-color: #ffffff6b;
  border-radius: 10px;
  padding: 5px 20px;
}

.main-page{
  display: flex;
  justify-content: space-between;
}

.login{
  padding: 0px 35px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.button-login{
  border-radius: 12px;
}

.price-card{
  display: flex;
}

.card{
  padding-top: 20px;
}

.table{
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
/* .button-color{
  background-color: black !important;
  color: white !important;
} */

.buy-button {
  background-color: grey; /* Set the background color to gray */
  color: white; /* Set the text color to white to ensure good visibility */
}

.buy-button:hover {
  background-color: red; /* Set the background color to red on hover */
}

.price-font{
  font-family: 'Montserrat', sans-seri;
  color : white;
  font-weight: 800;
}

.box-p{
  font-size: 11px;
  border: 1px solid gray;
  border-radius: 15px;
}

.buy-button{
  font-size: 10px;
}

.font-text{
font-family: 'Kantumruy Pro', sans-serif;
font-family: 'Poppins', sans-serif;
color: white;
font-weight: 600;
}

.back{
  background: linear-gradient(279deg, #a2ff9a 11.75%, #9bafad 54.01%, #AAEDFD 92.89%), #D9D9D9;
    opacity: 0.3;
    -webkit-filter: blur(177.206px);
    pointer-events: none;
    right: 0;
    top: 50%;
    transform: translateY(-40%);
}


.home-hero__scroll-down {
  width: 28px;
  height: 40px;
  border: 2px solid #ffffff;
  border-radius: 54px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 52px;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 3;
  cursor: pointer;
}
@media (max-width: 767px) {
  .home-hero__scroll-down {
    display: none;
  }
}
.home-hero__scroll-down span {
  background-color: #ffffff;
  height: 5px;
  width: 2px;
  display: block;
  margin-top: 8px;
  -webkit-animation: translate 2s linear infinite both;
          animation: translate 2s linear infinite both;
}

@keyframes translate {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.home-hero-animation-bottom {
  padding: 200px 0 98px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  position: relative;
  z-index: 2;
}

.meun-text{
  color: white;
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-weight: 600;

}

.meun-text:hover {
  color: #7ABC43;
}


.scroll-main::-webkit-scrollbar {
  width: 6px;
}

.scroll-main::-webkit-scrollbar-thumb {
  background: #f0f0f0d0;
  border-radius: 20px;
}

.scroll-main::-webkit-scrollbar-track {
  background: #dddddd0e;
  border-radius: 20px;
}


.price-font_model{
  font-size: 17px;
  font-weight: 600;
  color: white;
}


.payment-method-modal {
  background: white;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 768px;
}

.modal-header {
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.modal-content {
  padding: 20px;
}

.payment-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  cursor: pointer;
}

.payment-option.selected {
  border-color: #d1d1d1;
  background-color: #f7f7f7;
}

.payment-details {
  display: flex;
  align-items: center;
}

.payment-logo {
  width: 50px;
  height: 30px;
  background-size: cover;
  margin-right: 15px;
}

/* .visa-logo {
  background-image: url('/path-to-your-visa-logo.png');
} */

.payment-info .card-title {
  font-weight: bold;
  color: white;
}

.payment-info .card-detail,
.payment-info .default-indicator {
  font-size: 14px;
  color: #666;
}


.selection-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #666;
  background-color: transparent;
}

.payment-option.selected .selection-indicator {
  background-color: #666;
}

.modal-footer {
  padding: 20px;
  border-top: 1px solid #eee;
  text-align: center;
}

.add-new-card {
  background: none;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
}


.payment-methods-modal {
  display: flex;
  flex-direction: column;
}

.payment-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.payment-method:hover {
  background-color: #d4d4d46a;
}




.payment-details {
  flex-grow: 1;
  margin-left: 10px;
}

.card-title {
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
}

.card-detail,
.default-indicator {
  font-size: 14px;
  color: #666;
}

.selection-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #666;
  background-color: transparent;
}

.selection-indicator.selected {
  background-color: #666;
}

.add-new-card-action {
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
}

.khqr-logo {
  /* Use the actual path to your KHQR logo */
  background-image: url('/src/images/khqr.png');
}

.visa-logo {
  /* Use the actual path to your Visa logo */
  background-image: url('/src/images/stripe.png');
}


.payment-details .card-title {
  margin-left: 15px; /* Adjust as needed */
}

.selection-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #cbd5e0; /* use your border color */
  background: white;
}

.selection-indicator.selected {
  background: rgb(8, 209, 8); /* use your brand color */
}


.payment-methods-modal {
  display: flex;
  flex-direction: column;
}

.payment-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #e2e8f0; /* default border color */
  cursor: pointer;
  transition: border-color 0.2s; /* Smooth transition for border color change */
}

.payment-method.selected {
  border-color: green; /* Selected border color */
}

.payment-logo {
  /* Set a default size for your logos */
  width: 40px;
  height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
}

